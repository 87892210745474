export const navigationLinksList = [
  {
    id: 1,
    page: "about"
  },
  {
    id: 2,
    page: "gallery"
  },
  {
    id: 3,
    page: "music"
  },
  {
    id: 4,
    page: "contact"
  },
]